import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import Counter from './components/Counter.js';
import ImportExport from './components/ImportExport.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latest: {},
    };
    this.setLatest = this.setLatest.bind(this);
  }

  setLatest(name, date) {
    this.setState((prevState) => {
      let latest = {
        ...prevState.latest
      }
      latest[name] = date;
      return {
        latest: latest,
      }
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">Counter</h1>
        </header>
        <div className="App-container">
          <Counter name="P" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="M" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="A" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="E" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="R" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="C" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="CE" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="PN" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="S" latest={this.state.latest} onLatest={this.setLatest} />
          <Counter name="HJ" latest={this.state.latest} onLatest={this.setLatest} />
        </div>
        <ImportExport />
      </div>
    );
  }
}

export default App;
