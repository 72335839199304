import React, { Component } from 'react';
import './ImportExport.css';

class ImportExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    }
    this.handleChange = this.handleChange.bind(this);
    this.importData = this.importData.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  importData () {
    try {
      var jsonData = JSON.parse(this.state.data);
      for (var k in jsonData) {
        localStorage.setItem(k, jsonData[k]);
      }
      this.setState({data: "Please reload"});
    } catch (err) {
      this.setState({data: err});
    }
  }

  exportData () {
    this.setState({data: JSON.stringify(localStorage, null, "\t")})
  }

  handleChange (event) {
    this.setState({data: event.target.value});
  }

  render () {
    return (
      <div className="import-export">
        <div>
          <button onClick={this.importData}>Import</button>
          <button onClick={this.exportData}>Export</button>
        </div>
        <div>
          <textarea value={this.state.data} onChange={this.handleChange} />
        </div>
      </div>
    )
  }
}

export default ImportExport;
