const dateChangeHoursAfterMidnight = 5;  // how many hours after midnight starts the new day?
const getStartOfToday = new Date().setHours(dateChangeHoursAfterMidnight, 0, 0, 0);

const getDaysBetween = (date1, date2) => {
    const startDate = Math.min(date1, date2);
    const endDate = Math.max(date1, date2);
    // I move the clock backwards to consider that we don't consider date
    const startDateLessChangingHours = new Date(startDate - dateChangeHoursAfterMidnight * 3600 * 1000);
    const endDateLessChangingHours = new Date(endDate - dateChangeHoursAfterMidnight * 3600 * 1000);
    startDateLessChangingHours.setHours(0);
    startDateLessChangingHours.setMinutes(0);
    startDateLessChangingHours.setSeconds(0);
    startDateLessChangingHours.setMilliseconds(0);
    endDateLessChangingHours.setHours(0);
    endDateLessChangingHours.setMinutes(0);
    endDateLessChangingHours.setSeconds(0);
    endDateLessChangingHours.setMilliseconds(0);
    return Math.round((endDateLessChangingHours - startDateLessChangingHours) / 1000 / 3600 / 24);
}

const getDaysSince = (date) => {
    return getDaysBetween(date, new Date());
}

export {
    dateChangeHoursAfterMidnight,
    getDaysBetween,
    getDaysSince,
}