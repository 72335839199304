/**
 * See https://www.joshwcomeau.com/animation/3d-button/
 */

import React from 'react';
import './Button.css';

export default ({ onClick, children }) => {
    return (
        <button className="pushable" onClick={onClick}>
            <span className="shadow"></span>
            <span className="edge"></span>
            <span className="front">
                {children}
            </span>
        </button>
    )
}