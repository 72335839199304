import React, { Component } from 'react';
import moment from 'moment';
import Button from './Button';
import './Counter.css';
import { getDaysSince } from '../utils/date';

let getStartOfToday = () => {
  return new Date().setHours(5, 0, 0, 0);
};

class Counter extends Component {
  constructor(props) {
    super(props);
    var savedState = this.loadState();
    if (savedState === null) {
      this.state = { events: [] };
    } else {
      this.state = savedState;
    }
    this.addEvent = this.addEvent.bind(this);
  }
  addEvent() {
    let eventDate = new Date();
    this.setState(prevState => {
      var newState = {
        events: prevState.events.concat([eventDate])
      }
      this.saveState(newState);
      this.props.onLatest(this.props.name, eventDate);
      return newState;
    });
  };
  saveState(state) {
    localStorage.setItem(this.props.name, JSON.stringify(state));
  };
  loadState() {
    try {
      let state = JSON.parse(localStorage.getItem(this.props.name));
      if (state !== null) {
        state.events = state.events.map(e => new Date(e));
        if (state.events.length > 0) {
          this.props.onLatest(this.props.name, state.events.slice(-1)[0]);
        }
        return state;
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };
  getTodayEvents() {
    let dayStartsAt = getStartOfToday();
    return (this.state.events.filter(value => value > dayStartsAt)).length
  };
  getSevenDaysEvents() {
    const weekAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
    return (this.state.events.filter(value => value > weekAgo)).length
  };
  getThirtyDaysEvents() {
    const monthAgo = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
    return (this.state.events.filter(value => value > monthAgo)).length
  };
  getYearEvents() {
    var yearAgo = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);
    return (this.state.events.filter(value => value > yearAgo)).length
  };
  getLast() {
    const date = this.state.events.slice(-1)[0];
    let daysSince = getDaysSince(date);
    if (daysSince === 0) {
      daysSince = `today (at ${moment(date).format("HH:mm")})`;
    }
    if (daysSince === 1) {
      daysSince = `yesterday (at ${moment(date).format("HH:mm")})`;
    }
    if (daysSince > 1) {
      daysSince = `${daysSince} days ago (${moment(date).format("DD/MM/YYYY")})`;
    }
    return daysSince;
  }
  render() {
    return (
      <div className="counter">
        <h2>{this.props.name}</h2>
        <div className="button">
          <Button onClick={this.addEvent}>+1</Button>
        </div>
        {this.state.events.length > 0 &&
          <div className="notes">
            <p>last: {this.getLast()}</p>
            <p>today: {this.getTodayEvents()}</p>
            <p>last 7 days: {this.getSevenDaysEvents()}</p>
            <p>last 30 days: {this.getThirtyDaysEvents()}</p>
            <p>last year: {this.getYearEvents()}</p>
            {false && Object.keys(this.props.latest).map((i) => {
              if (i !== this.props.name) {
                let sinceCounter = this.state.events.filter(value => value > this.props.latest[i]).length;
                return (sinceCounter > 0 &&
                  <p>since last {i}: {sinceCounter}</p>
                )
              }
            })}
          </div>
        }
      </div>
    )
  }
}

export default Counter;
